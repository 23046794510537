import React from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "./shared/Loader";

// Layouts
import Layout2 from "./shared/layouts/Layout2";
import LayoutBlank from "./shared/layouts/LayoutBlank";
import { getDomainInfo } from "./utils/userAccess";

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

// ---
// Default application layout

export const DefaultLayout = Layout2;
// ---
// Document title template
const domainInfo = getDomainInfo();
export const titleTemplate = domainInfo.domain === "Goldsainte"
  ? "%s - GoldSainte.com"
  : domainInfo.domain === "TravelThru"
    ? "%s - TravelThru.com"
    : "%s - ChauffeurBookingSoftware.com";
// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object
export const defaultRoute = "/";//dispatch?h=24";
export const routes = [
  {
    path: '/',
    component: lazy(() => import('./components/Default.tsx')),
    isPublic: true,
    layout: LayoutBlank,
  },
  {
    path: "/bookings",
    component: lazy(() => import("./components/pages/clientPages/BookingsPage.tsx")),
  },
  {
    path: "/bookings/admin/:id?",
    component: lazy(() => import("./components/pages/dispatch/BookingDetailsAdminPage")),
  },
  {
    path: "/bookings/booking/:id?",
    component: lazy(() => import("./components/pages/dispatch/BookingDetails/BookingDetailsPage")),
  },
  {
    path: "/booking/payment/:id?",
    isPublic: true,
    component: lazy(() => import("./components/pages/booking/BookingPaymentPage.tsx")),
    layout: LayoutBlank,
  },
  //http://localhost:3000/#/bookingdetails/10ed0154-f7cf-4c92-bab0-95062b4e7340
  {
    path: "/bookingdetails/:id?",
    isPublic: false,
    component: lazy(() => import("./components/pages/dispatch/BookingDetails/BookingDetails")),
    layout: LayoutBlank,
  },
  //http://localhost:3000/#/reservation/10ed0154-f7cf-4c92-bab0-95062b4e7340
  {
    path: "/reservation/:id?",
    isPublic: true,
    component: lazy(() => import("./components/pages/clientPages/ClientReservation/ClientReservationPage")),
    layout: LayoutBlank,
  },
  {
    path: "/voucher/:id?",
    isPublic: true,
    component: lazy(() => import("./components/pages/clientPages/ClientReservation/ClientVoucherPage")),
    layout: LayoutBlank,
  },
  {
    path: "/client-registration/:id?",
    isPublic: true,
    component: lazy(() => import("./components/pages/clientPages/RegistrationPage.tsx")),
    layout: LayoutBlank,
  },
  {
    path: "/registration/:code?",
    isPublic: true,
    component: lazy(() => import("./components/pages/clientPages/ClientRegistration/TravelAgentRegistration.tsx")),
    layout: LayoutBlank,
  },
  {
    path: "/finish-registration/:rid?",
    isPublic: true,
    component: lazy(() => import("./components/pages/clientPages/ClientRegistration/CompleteTravelAgentRegistration.tsx")),
    layout: LayoutBlank,
  },
  {
    path: "/book/:id?",
    component: lazy(() => import("./components/pages/clientPages/EditBookingPage")),
  },
  {
    path: "/driver-bookings",
    component: lazy(() => import("./components/pages/driver/DriverBookingsPage")),
  },
  {
    path: "/dbd/:hash",
    isPublic: true,
    layout: LayoutBlank,
    component: lazy(() =>
      import("./components/pages/driver/BookingDetails/BookingDetailsPage")
    ),
  },
  {
    path: "/dbdetails/:id?",
    isPublic: true,
    layout: LayoutBlank,
    component: lazy(() =>
      import("./components/pages/driver/BookingDetails/BookingDetailsPage")
    ),
  },
  {
    path: "/booking/:id?",
    component: lazy(() => import("./components/pages/booking/EditBooking.jsx")),
  },
  {
    path: "/dispatch",
    component: lazy(() => import("./components/pages/dispatch/Dashboard.jsx")),
  },
  {
    path: "/live-map",
    component: lazy(() => import("./components/pages/liveMap/LiveMapPage.jsx")),
  },
  {
    path: "/suppliers",
    component: lazy(() =>
      import("./components/pages/suppliers/SuppliersPage.jsx")
    ),
  },
  {
    path: "/clients",
    component: lazy(() => import("./components/pages/clients/ClientsPage.jsx")),
  },
  {
    path: "/client/companies/:id?",
    component: lazy(() => import("./components/pages/clients/company/ClientCompaniesPage.tsx")),
  },
  {
    path: "/coupons",
    component: lazy(() => import("./components/pages/coupons/CouponsPage.jsx")),
  },
  {
    path: "/carowners",
    component: lazy(() =>
      import("./components/pages/goldsainte/carOwners/CarOwners.jsx")
    ),
  },
  {
    path: "/vehicles",
    component: lazy(() =>
      import("./components/pages/goldsainte/vehicles/Vehicles.jsx")
    ),
  },
  {
    path: "/vehicles-types",
    component: lazy(() =>
      import("./components/pages/goldsainte/vehiclesTypes/VehiclesTypes.tsx")
    ),
  },
  {
    path: "/rentals",
    component: lazy(() =>
      import("./components/pages/goldsainte/carRentals/CarRentals.jsx")
    ),
  },
  {
    path: "/reports-bookings",
    component: lazy(() => import("./components/pages/reports/Performance.jsx")),
  },
  {
    path: "/operations",
    component: lazy(() => import("./components/pages/reports/Operations")),
  },
  {
    path: "/pickup-points",
    component: lazy(() => import("./components/pages/reports/Searches/PickupPoints")),
    isPublic: true,
  },
  {
    path: "/bookingdotcom-stats",
    component: lazy(() =>
      import("./components/pages/booking.com/Statistics.jsx")
    ),
  },
  {
    path: "/bookingdotcom-requests",
    component: lazy(() =>
      import("./components/pages/booking.com/Requests.jsx")
    ),
  },
  // {
  //   path: "/companies",
  //   component: lazy(() =>
  //     import("./components/pages/clients/company/ClientCompaniesPage.tsx")
  //   ),
  // },
  {
    path: "/company-registration/:id?",
    isPublic: true,
    component: lazy(() =>
      import("./components/pages/authentication/RegisterSupplier.jsx")
    ),
    layout: LayoutBlank,
  },
  {
    path: "/car-owner-registration/:id?",
    isPublic: true,
    component: lazy(() =>
      import("./components/pages/registration/RegisterCarOwner.jsx")
    ),
    layout: LayoutBlank,
  },
  {
    path: "/driver-check/:id?",
    component: lazy(() =>
      import("./components/pages/booking/DriverBookingInfoPage.jsx")
    ),
    isPublic: true,
    layout: LayoutBlank,
  },
  {
    path: "/payments",
    component: lazy(() =>
      import("./components/pages/payments/PaymentsPage.jsx")
    ),
  },
  {
    path: "/payouts-outstanding",
    component: lazy(() =>
      import("./components/pages/payments/OutstandingPayments.jsx")
    ),
  },
  {
    path: "/admin-settings",
    component: lazy(() => import("./components/pages/admin/Admin.jsx")),
  },
  {
    path: "/my-profile",
    component: lazy(() =>
      import("./components/pages/authentication/profile/MyProfilePage.jsx")
    ),
  },
  {
    path: "/login",
    isPublic: true,
    component: lazy(() =>
      import("./components/pages/authentication/Login.jsx")
    ),
    layout: LayoutBlank,
  },
  {
    path: "/reset-password/:email?",
    isPublic: true,
    component: lazy(() => import("./components/pages/authentication/RequestResetPasswordPage")),
    layout: LayoutBlank,
  },
  {
    path: "/setpassword/:token?",
    isPublic: true,
    component: lazy(() => import("./components/pages/authentication/SetPasswordPage")),
    layout: LayoutBlank,
  },
  {
    path: "/delete-account",
    isPublic: true,
    component: lazy(() =>
      import("./components/pages/authentication/DeleteAccount.tsx")
    ),
    layout: LayoutBlank,
  },
  {
    path: "/page-2",
    component: lazy(() => import("./components/pages/authentication/Login")),
  },
  {
    path: "/overview",
    component: lazy(() => import("./components/pages/dispatch/bookingStats/Overview.jsx")),
  },
  {
    path: "/drivers",
    component: lazy(() => import("./components/pages/adminDriver/DriversPage.jsx")),
  },
  {
    path: "/edit-prices",
    component: lazy(() => import("./components/pages/pricing/EditPrices")),
  },
  {
    path: "/services",
    component: lazy(() => import("./components/pages/services/Services")),
  },
];
